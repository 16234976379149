import React from 'react';

const Property = ({prop, text, hasDagsintag }) => {
    if (!prop) return null;

    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
            {hasDagsintag &&
                <td></td>
            }
        </tr>
    ); 
};

const Mattkvalificerarebasmangd = (naringsinfo) => {
    if (!naringsinfo.Mattkvalificerarebasmangd) return null;

    switch (naringsinfo.Mattkvalificerarebasmangd) {
        case 'Gram': 
            return 'gr';
        case 'Milliliter':
            return 'ml';
        default: 
            return null;
    }
}

const Basmangd = ({naringsinfo, hasDagsintag}) => {
    if (!naringsinfo.Basmangdsdeklaration) return null;
    
    return (
        <tr>
            <th>Basmängd:</th>
            <td>Per {naringsinfo.Basmangdsdeklaration_Formatted} <Mattkvalificerarebasmangd {...naringsinfo} /></td>
            {hasDagsintag &&
                <td></td>
            }
        </tr>
    );
};

const Naringsvarden = (naringsinfo) => {
    if (!naringsinfo.Naringsvarden) return null;
    if (naringsinfo.Naringsvarden.length === 0) return null;


    const precision = (val) => val === 'Mindre än' ? '<' : null;
    const Dagsintag = ({val, hasDagsintag}) => {        
        if (val && val > 0) {
            return <td>{val} (*)</td>;
        }

        if (hasDagsintag) return <td></td>;

        return null;
    };

    const naringsvarden = naringsinfo.Naringsvarden.filter(n => n.Kod !== 'SUGAR-' && n.Kod !== 'FASAT').map((naringsvarde, i) => {
        
        //Ta bort denna när NACL är borta ur index
        if (naringsvarde.Kod ===  'NACL') return null;
        
        const fsatArr = naringsinfo.Naringsvarden.filter(n => n.Kod === 'FASAT');
        const sugarArr = naringsinfo.Naringsvarden.filter(n => n.Kod === 'SUGAR-');
    
        return (    
            <React.Fragment key={`naringsvarde${i}`}>
                <tr>
                    <th>
                        {naringsvarde.Benamning}:
                    </th>
                    <td>
                        {precision(naringsvarde.Precision)} {naringsvarde.Mangd_Formatted} {naringsvarde.Enhet}
                    </td>
                    <Dagsintag val={naringsvarde.Dagsintag_Formatted} hasDagsintag={naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0)} />
                </tr>
                {naringsvarde.Kod === 'FAT' && fsatArr.length > 0 && fsatArr.map((fsat, x) => {
                    return (
                        <tr key={`fsat${x}`}>
                            <th>
                                {fsat.Benamning}:
                            </th>
                            <td>
                                {precision(fsat.Precision)} {fsat.Mangd_Formatted} {fsat.Enhet}
                            </td>
                            <Dagsintag val={fsat.Dagsintag_Formatted} hasDagsintag={naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0)} />
                        </tr>);
                    
                })}

                {naringsvarde.Kod === 'CHOAVL' && fsatArr.length > 0 && sugarArr.map((sugar, y) => {
                    return (
                        <tr key={`sugar${y}`}>
                            <th>
                                {sugar.Benamning}:
                            </th>
                            <td>
                                {precision(sugar.Precision)} {sugar.Mangd_Formatted} {sugar.Enhet}
                            </td>
                            <Dagsintag val={sugar.Dagsintag_Formatted} hasDagsintag={naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0)} />
                        </tr>);
                    
                })}
            </React.Fragment>
        );
    });

    return (
        <>
            {naringsvarden}
            {naringsinfo.Naringsvarden && naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0) && 
                <tr className="product-sheet__table-footer">
                    <td colSpan="3"> 
                        <span>
                            * (Andel av dagsintag %)
                        </span>
                    </td>
                </tr>  
            }
        </>
    );
}

const RenderNaringsinfo = ({heading = false, ...product}) => {
    return product.Naringsinfo.map((naringsinfo, i) => {
        return (
            <React.Fragment key={`naringsinfo${i}`}>
                {heading &&
                    <h3 className="product-sheet-box__tableheading">{heading}</h3>
                }
                <table key={`naringsinfo${i}`} className={product.className}>
                    <tbody>
                        <Property prop={naringsinfo.Tillagningsstatus} text="Tillagningsstatus:" hasDagsintag={naringsinfo.Naringsvarden && naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0)} />
                        <Property prop={naringsinfo.Intagningsrekommendationstyp} text="Intagsrekommendation:" hasDagsintag={naringsinfo.Naringsvarden && naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0)} />
                        <Basmangd naringsinfo={naringsinfo} hasDagsintag={naringsinfo.Naringsvarden && naringsinfo.Naringsvarden.find(element => element.Dagsintag && element.Dagsintag > 0)} />
                        <Naringsvarden {...naringsinfo} />
                    </tbody>
                </table>
            </React.Fragment>
        );
    });
};

const Naringsinformation = ({isBox = true, ...product}) => {
    if (!product.Naringsinfo) return null;
    if (product.Naringsinfo.length === 0) return null;

    if (!isBox) {
        return <RenderNaringsinfo {...product} heading="Näringsdeklaration" className="product-sheet__table product-sheet__table--striped product-sheet__table--margin-top" />;
    }

    return (
        <section className="product-sheet-box product-sheet-box--break">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">Näringsvärde</h2>
                <RenderNaringsinfo {...product} className="product-sheet__table product-sheet__table--striped product-sheet__table--align-right" />
            </div>
        </section>
    );
};

export default Naringsinformation;