import React from 'react';
import { generateHTMLFromTextArea } from '../../helpers/html';

const About = (newsroom) => {
    if (!newsroom.CompanyDescription) return null;
    return (
        <div className="newsroom-contact__wrapper">
            <h2>Om {newsroom.CompanyName}</h2>
            <div dangerouslySetInnerHTML={{__html: generateHTMLFromTextArea(newsroom.CompanyDescription)}}></div>
        </div>
    );
};

export default About;